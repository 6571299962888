import { CardElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js'
import React from 'react'
import Spinner from 'react-spinkit'
import { t } from 'translations/translationFunctions'
import { createSetupIntent, setPaymentMethod, registerFailedBuyNowAttempt } from '../../api/api'
import NavigationFooter from '../../components/navigationFooter/NavigationFooter'
import TermsOfService from './TermsOfService/'
import './PaymentForm.css'
import {
  Stripe,
  StripeCardElement,
  StripeCardElementChangeEvent,
  StripeElements,
} from '@stripe/stripe-js/types/stripe-js'
import cn from 'classnames'
import { showError } from 'utils/toastify'
import Popup from '../popup/Popup'

const stripeTermsLink = 'https://stripe.com/en-se/legal/ach-payments/authorization'
const stripePrivacyLink = 'https://stripe.com/en-se/privacy'
const CARD_INPUT_REFOCUS_MS: number = 0 // 0 means off.

interface IIntermediateProps {
  termsOfTradeRef: string
  termsOfServiceRef: string
  contractPrettyIdentifier: string
  isStripePayment: boolean
  onBack?: () => void
  onSuccessfulPayment: () => void
  token: string
}

interface IPaymentProps {
  stripe: Stripe
  elements: StripeElements
}

interface IState {
  areAllTermsChecked: boolean
  isCardComplete: boolean
  validationError: string | undefined
  handlingSubmit: boolean
  isFocused: boolean
}

type TProps = IIntermediateProps & IPaymentProps

const stripeFormOptions = {
  hidePostalCode: true,
  style: {
    base: { fontSize: '18px' },
    invalid: { color: '#dc3545' },
  },
}

const errorCodes = {
  invalid_number: 'Invalid card number',
  invalid_expiry_year: 'Invalid expiration year',
  invalid_expiry_month: 'Invalid expiration month',
  incomplete_cvc: 'Incomplete CVC',
  invalid_expiry_year_past: 'Expiration year already passed',
  invalid_expiry_month_past: 'Expiration month already passed',
}

/**
 * @docs — https://stripe.com/docs/stripe-js/react#element-components
 */
class PaymentForm extends React.Component<TProps, IState> {
  public state = {
    areAllTermsChecked: false,
    isCardComplete: false,
    validationError: undefined,
    handlingSubmit: false,
    isFocused: true,
  }

  public render() {
    const { termsOfServiceRef, termsOfTradeRef, isStripePayment, onBack } = this.props
    const { handlingSubmit, validationError, areAllTermsChecked, isCardComplete, isFocused } = this.state

    const canProceed = !handlingSubmit && areAllTermsChecked && (!isStripePayment || isCardComplete)

    const cardFormWrapperClassName = cn('card-form__wrapper', { 'card-form__wrapper--focused': isFocused })

    return (
      <div className="payment-form-wrapper col-12">
        <div className="tos-wrapper">
          <TermsOfService
            termsOfServiceRef={termsOfServiceRef}
            termsOfTradeRef={termsOfTradeRef}
            onChangeAllTermsChecked={this.handleAllTermsChecked}
          />
        </div>
        {isStripePayment && (
          <div className={cardFormWrapperClassName}>
            <CardElement
              onChange={this.handleCardChange}
              options={stripeFormOptions}
              onReady={this.handleFocus}
              onFocus={this.handleCardInputFocus}
              onBlur={this.handleCardInputBlur}
            />
          </div>
        )}
        <span className="card-form__error-msg">
          {/* {validationError && t(errorCodes[validationError!] || validationError)} */}
          {(validationError as any) && t(errorCodes[validationError!] || (validationError as any))}
        </span>
        <span className="stripe-info">
          {t('Payments are made through Stripe')}
          {/* <div className="stripe-links"> */}
          {
            <a href={stripeTermsLink} className="stripe-links" target="_blank" rel="noopener noreferrer">
              {t('Terms')}
            </a>
          }
          {
            <a href={stripePrivacyLink} className="stripe-links" target="_blank" rel="noopener noreferrer">
              {t('Privacy')}
            </a>
          }
          {/* </div> */}
        </span>
        <NavigationFooter
          className="nav-wrapper"
          shouldRedirect={false}
          nextCallback={this.handleSubmit}
          isLoadingNext={handlingSubmit}
          isNextAvailable={canProceed}
          {...(onBack ? { prevCallback: onBack } : { hidePrev: true })}
          nextTitle={t('Confirm purchase')}
          displayRightArrowIcon={false}
        />
        {handlingSubmit && (
          <Popup
            header="Your purchase is still being processed, please wait"
            text="Do not close this window until it is complete"
          />
        )}
      </div>
    )
  }

  private handleAllTermsChecked = (areAllTermsChecked: boolean) => {
    this.setState({ areAllTermsChecked })
    if (areAllTermsChecked && this.props.elements) {
      const cardElement = this.props.elements.getElement('card')
      this.handleFocus(cardElement)
    }
  }

  private handleCardChange = (e: StripeCardElementChangeEvent) =>
    this.setState({
      isCardComplete: e.complete,
      validationError: e.error && e.error.code,
    })

  private displayError = (errorMessage: string = t('An unknown error occurred')) => showError(errorMessage)

  private handleFocus = (element: StripeCardElement | null) => {
    if (!element) return
    element.focus()
    this.setState({ isFocused: true })
  }

  private handleCardInputFocus = () => this.setState({ isFocused: true })

  private handleCardInputBlur = () => {
    this.setState({ isFocused: false })

    if (!CARD_INPUT_REFOCUS_MS) {
      // Do not activate card-input-refocus.
    } else {
      setTimeout(() => {
        const cardElement = this.props.elements.getElement('card')
        this.handleFocus(cardElement)
      }, CARD_INPUT_REFOCUS_MS)
    }
  }

  private handleSubmit = async () => {
    const { contractPrettyIdentifier, onSuccessfulPayment, isStripePayment, token } = this.props

    if (this.state.handlingSubmit) {
      return
    }

    this.setState({ handlingSubmit: true })
    try {
      const payment_method = isStripePayment ? await this.handleStripeSubmit() : ''

      const { errorData: paymentMethodError, data: paymentMethodData } = await setPaymentMethod(
        contractPrettyIdentifier,
        {
          contractId: contractPrettyIdentifier,
          paymentMethodId: payment_method,
        },
        token,
      )
      if (paymentMethodError || !paymentMethodData) {
        return this.displayError(paymentMethodError ? paymentMethodError.message : 'Error calling setPaymentMethod')
      }

      if (paymentMethodData.status === 'processing') {
        return this.displayError(
          t(
            'Your order has been registered, as soon as the payment is processed the contract will be activated and we will send you an email about the activation',
          ) + '.',
        )
      }
      if (paymentMethodData.status !== 'succeeded') {
        return this.displayError(paymentMethodData.status)
      }
      return setTimeout(onSuccessfulPayment, 0)
    } catch (error) {
      return this.displayError((error as any).message || error)
    } finally {
      this.setState({ handlingSubmit: false })
    }
  }

  private handleStripeSubmit = async (): Promise<string> => {
    const { contractPrettyIdentifier, stripe, elements, token } = this.props
    if (!stripe || !elements) {
      throw Error('An unknown error occured')
    }

    const cardElement = elements.getElement('card')
    if (!cardElement) {
      throw Error('An unknown error occured')
    }

    const { errorData: setupIntentError, data: setupIntentData } = await createSetupIntent(
      contractPrettyIdentifier,
      token,
    )

    if (setupIntentError || !setupIntentData) {
      throw Error(setupIntentError ? setupIntentError.message : 'Error calling createSetupIntent')
    }
    const { clientSecret } = setupIntentData
    const { error: cardSetupError, setupIntent } = await stripe.confirmCardSetup(clientSecret, {
      payment_method: { card: cardElement },
    })

    if (cardSetupError) {
      registerFailedBuyNowAttempt(contractPrettyIdentifier, token)
      throw Error(cardSetupError.message)
    }
    if (!setupIntent || !setupIntent.payment_method) {
      registerFailedBuyNowAttempt(contractPrettyIdentifier, token)
      throw Error('Missing payment method id in stripe.confirmCardSetup response')
    }
    return typeof setupIntent.payment_method === 'string' ? setupIntent.payment_method : setupIntent.payment_method.id
  }
}

const InjectedPaymentForm: React.SFC<IIntermediateProps> = (props) => (
  <ElementsConsumer>
    {({ elements, stripe }) => <PaymentForm elements={elements!} stripe={stripe!} {...props} />}
  </ElementsConsumer>
)

type TWrapperProps = IIntermediateProps & {
  stripePromise: Promise<Stripe | null> | null
}

const Wrapper: React.SFC<TWrapperProps> = ({ stripePromise, ...props }) => {
  if (!stripePromise && props.isStripePayment) {
    return (
      <div className="payment-form__spinner-wrapper">
        <Spinner className="payment-form__spinner" name="circle" color="black" fadeIn="none" />
      </div>
    )
  }

  return (
    <Elements stripe={stripePromise}>
      <InjectedPaymentForm {...props} />
    </Elements>
  )
}

export default Wrapper
